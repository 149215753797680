body{
  overflow-x: hidden;
}
.section_heading {
  padding: 10px 50px 10px 45px;
}
.section_heading p {
  color: #575757;
}
.section_heading a {
  text-decoration: none;
  color: #0e1e2c;
  font-weight: 700;
}
.section_contactus .contact-head h3 {
  border-bottom: 2px solid #0081C3;
}
@media screen and (max-width:767px){
  .section_heading {
    padding: 0px 0px 10px 0px;
  }
}
