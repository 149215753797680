.contact-form {
  min-width: 48%;
}

.contact-form .inputFld {
  border: 1px solid #C9C9C9;
  border-radius: 0px;
  padding: 17px 15px;
  color: #8F8F8F;
}

.pop-up .popImage {
  min-width: 50%;
}

.pop-up .popImage img {
  max-width: 470px;
  min-height: 100%;
}

.contact-form h2 {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  max-width: 262px;
  margin: 0 auto;
  color: #000000;
  text-transform: uppercase;
}

@media screen and (max-width : 767px) {
  .pop-up .popImage{
    display: none;
  }
}