.contact-form .inputFld {
    border: 1px solid #C9C9C9;
    border-radius: 0px;
    padding: 17px 15px;
    color: #8F8F8F;
}

.contact-form h2 {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    max-width: 262px;
    margin: 0 auto;
    color: #000000;
    text-transform: uppercase;
}

.contact-form button {
    background: #0081C3;
    color: #fff;
    border-radius: 5px;
    border: 0px;
    font-size: 15px;
    font-weight: 400;
    padding: 11px 51px;
}

.contact-form input{
    height: 40px;
}

form .phone-input {
    width: 100%;
    margin-bottom: 25px;
}